import React, {useState, useEffect} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {confirmAlert} from 'react-confirm-alert';
import {useForm} from 'react-hook-form';
import useOrdersHook from '../../../api/orders';
import {ViewOrders} from '../../../components/admin/orders/ViewOrders';
import {FormOrders} from '../../../components/admin/orders/FormOrders';
import {
    Spinner,
    Pagination,
    Message,
    Confirm, Search,
} from '../../../components';
import DefaultOrdersIcon from "../../../media/vending-machine.svg";
import useSimpleDataHook from "../../../api/simpleData";

const Orders = () => {
    const [page, setPage] = useState(1)
    const [id, setId] = useState(null)
    const [edit, setEdit] = useState(false)
    const [q, setQ] = useState('')

    const {getOrders, postOrder, updateOrder, deleteOrder} = useOrdersHook({
        page,
        q,
    })

    const { getSimpleMachines} = useSimpleDataHook({
        limit: 100000, page: 1
    });

    const {data: dataMachines} = getSimpleMachines;

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            cartState: 'IDLE'
        },
    })

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getOrders

    const {
        // data: responseUpdate,
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate,
    } = updateOrder

    const {
        // data: responseDelete,
        isLoading: isLoadingDelete,
        mutateAsync: mutateAsyncDelete,
    } = deleteOrder

    const {
        // data: responsePost,
        isLoading: isLoadingPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost,
    } = postOrder

    const formCleanHandler = () => {
        if (isSuccessPost || isSuccessUpdate) {
            setEdit(false);
            reset();
        }
    }

    useEffect(() => {
        if (isSuccessPost || isSuccessUpdate){
            setEdit(false);
        }
    }, [isSuccessPost, isSuccessUpdate])

    useEffect(() => {
        if (q.trim() || page) {
            refetch();
        }
    }, [page, q, refetch]);


    const searchHandler = (e) => {
        e.preventDefault()
        refetch()
        setPage(1)
    }

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)))
    }


    const submitHandler = async (itemData) => {
        try {
            console.log('submitHandler');

            // console.log('the_array:', imageData);
            // console.log(itemData);
            if (edit) {

                await mutateAsyncUpdate({
                    _id: id,
                    order_id: itemData.order_id,
                    machine: itemData.machine,
                    orderItems: itemData.orderItems,
                    machine_current_cash: itemData.machine_current_cash,
                    paymentMethod: itemData.paymentMethod,
                    totalQuantity: itemData.totalQuantity,
                    totalPrice: itemData.totalPrice,
                    cartState: itemData.cartState,
                    transactionStatus: itemData.transactionStatus,
                    transactionStatusMessage: itemData.transactionStatusMessage,
                    orderTime: itemData.orderTime,
                    synced_status: itemData.synced_status,
                    synced_at: itemData.synced_at,
                    synced_id: itemData.synced_id,
                    synced_error_message: itemData.synced_error_message,
                    qr_code_data: itemData.qr_code_data,
                    qr_code_link: itemData.qr_code_link
                });
                console.log('RESPONSE UPDATE')
            } else {
                console.log('RESPONSE POST')

                await mutateAsyncPost(itemData);
            }

            // Request succeeded
            if (edit) {
                console.log('SUCCESSFULL EDIT');
            } else {
                console.log('SUCCESSFULL NEW');
            }
        } catch (error) {
            // Request failed
            console.error(error);
            if (edit) {
                console.log('FAILED EDIT POST')

            } else {
                console.log('FAILED NEW POST')
            }
            if (error.response && error.response.status === 500) {
                console.log('FAILED POST 500')
            }
        }
    };

    const editHandler = (order) => {
        console.log('editHandler');
        console.log(order);
        setId(order._id)
        setEdit(true);
        setValue('order_id', order.order_id);
        setValue('machine', order.machine);
        setValue('orderItems', order.orderItems);
        setValue('machine_current_cash', order.machine_current_cash);
        setValue('paymentMethod', order.paymentMethod);
        setValue('totalQuantity', order.totalQuantity);
        setValue('totalPrice', order.totalPrice);
        setValue('cartState', order.cartState);
        setValue('transactionStatus', order.transactionStatus);
        setValue('transactionStatusMessage', order.transactionStatusMessage);
        setValue('orderTime', order.orderTime);
        setValue('synced_status', order.synced_status);
        setValue('synced_at', order.synced_at);
        setValue('synced_id', order.synced_id);
        setValue('synced_error_message', order.synced_error_message);
        setValue('qr_code_data', order.qr_code_data);
        setValue('qr_code_link', order.qr_code_link);
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Orders</title>
                    <meta property='og:title' content='Orders' key='title'/>
                </Helmet>
            </HelmetProvider>


            <div className='view-list-top-bar'>

                <h3 className='fw-light text-muted text-center mb-3'>
                    Orders List <sup className='fs-6'> [{data && data.total}] </sup>
                    <img className="orders-list-title-icon admin-list-title-icon" src={DefaultOrdersIcon}
                         alt="Default Marker Icon"/>
                </h3>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <button
                        className='btn add-new-entry-button align-self-end'
                        data-bs-toggle='modal'
                        data-bs-target='#orderModal'
                        onClick={() => formCleanHandler()}
                    >
                        Add New Order
                    </button>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                <div className='col-auto full-search'>
                    <Search
                        placeholder='Search by id,status'
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>


            {isLoading ? (
                <Spinner/>
            ) : isError ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                <ViewOrders
                    data={data}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    isLoadingDelete={isLoadingDelete}
                    // images_path={imagesPath}
                />
            )}
            <div className='pagination-bottom-block'>
                <Pagination data={data} setPage={setPage}/>
            </div>

            <FormOrders
                edit={edit}
                formCleanHandler={formCleanHandler}
                isLoading={isLoading}
                isError={isError}
                isErrorUpdate={isErrorUpdate}
                errors={errors}
                isLoadingUpdate={isLoadingUpdate}
                isLoadingPost={isLoadingPost}
                register={register}
                handleSubmit={handleSubmit}
                submitHandler={submitHandler}
                watch={watch}
                error={error}
                dataMachines={dataMachines}
                data={data}
            />

        </>
    )
}

export default Orders
